header {
	padding: 12px 0;
  border-bottom: 1px solid #DCDBD6;
}

header .nav {
  display: none;
}

header a {
	color: #2A2720;
}

header a:hover {
	color: #2A2720;
}

header .nav-link::after {
	background-color: #2A2720;
}

header img {
	opacity: 1;
}

@media (min-width: 576px) {
  header .nav {
    display: block;
  }

  header .header-main {
    flex-direction: row;
    justify-content: space-between;
  }
}
