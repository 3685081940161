.banner {
  background-color: #F3F0E5;
}

.banner .banner-main {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.banner .presentation {
  max-width: 400px;
  text-align: center;
  margin-top: 50px;
}

.banner .description p {
  margin: 20px 0 30px 0;
  padding: 0px;
  font-size: 18px;
  line-height: 1.8;
}

.banner .actions {
  display: none;
  margin-top: 24px;
}

.banner .mockup {
  margin-top: 20px;
  margin-bottom: -140px;
}

.banner .mockup img {
  width: 300px;
  height: 350px;
  margin-left: -20px;
}

@media (min-width: 576px) {
  .banner .mockup img {
    width: 500px;
    height: 584px;
    margin-left: -20px;
  }
}

@media (min-width: 1012px) {
  .banner .banner-main {
    justify-content: space-between;
  }
  .banner .presentation {
    text-align: left;
    margin-top: 0px;
    max-width: 580px;
  }
  .banner .actions {
    display: block;
  }
  .banner .mockup {
    margin-top: 50px;
  }
}
