footer {
	color: #333 !important;
	padding: 90px 0;
  text-align: center;
  /* border-top: 1px solid #DCDBD6; */
}

footer span {
	margin-bottom: 2px;
}

footer a {
	line-height: 2.2;
	color: black;
	text-decoration: none;
}

footer a:hover {
	text-decoration: underline;
}

footer p {
	font-size: 16px;
	margin-top: 11px;
	line-height: 1.7;
}

footer h1 {
	font-size: 18px;
	font-weight: 500;
	margin-bottom: 15px;
}

footer .description {
  max-width: 450px;
  margin-bottom: 0;
}

footer h2 {
  margin-top: 20px;
  font-size: 20px;
  font-weight: 500;
}

footer .footer-download a {
  line-height: 1;
}

footer .footer-download img {
  margin-top: 10px;
}

footer hr {
  width: 100%;
  height: 0;
  border-bottom: 0;
  border-top: 1px solid #e6e6e6;
  margin: 36px 0;
}

footer .disclaimer {
  width: 100%;
  font-size: 13px;
}

/* @media (min-width: 1012px) {
  footer {
    text-align: left;
  }

  footer .footer-main {
    width: 100%;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
  }

  footer .footer-slogan {
    align-items: flex-start;
  }

  footer .footer-download {
    align-items: flex-end;
  }

  footer .disclaimer {
    flex-direction: row;
    justify-content: space-between;
  }
} */
