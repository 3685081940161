nav {
	font-size: 14px;
	font-family: 'Roboto', sans-serif;
}
nav a {
	display: inline-block;
  margin: 0px 0 0 45px;
	color: #fff;
	font-weight: 500;
	text-decoration: none;
	text-transform: uppercase;
}
/* nav ul {
	list-style: none;
}
nav li {
	display: inline-block;
} */

/* nav .nav-link::after {
	content: '';
	display: block;
	width: 0;
	height: 2px;
	background: #fff;
	transition: width .3s;
}
nav .nav-link:hover::after {
	width: 100%;
	transition: width .3s;
} */
nav a.btn-download {
  border-radius: 5px;
  border: 1px solid #1a1a1a;
  background-color: #fff;
  padding: 8px 10px;
  color: #1a1a1a;
  text-transform: none;
}
nav a.btn-download:hover {
  text-decoration: underline;
}
