.content {
  text-align: center;
}

.content hr {
	width: 50px;
	height: 0;
	border-top: none;
	border-bottom: 1px solid #2A2720;
	margin: 8px 0 15px 0;
}

.content .overview {
  margin-top: 175px;
  padding-bottom: 60px;
}

.content .overview p {
  max-width: 800px;
  font-size: 18px;
}

.content .highlights {
  width: 100%;
  margin-top: 60px;
}

.content .highlights .highlights-item {
  max-width: 450px;
  margin-bottom: 30px;
}

.content .highlights .highlights-item p {
  font-size: 16px;
  color: #666;
  line-height: 2;
}

.content #video {
  padding: 80px 0px 180px 0px;
  background-color: #f8f8f8;
}

.content #video .video-main {
  width: 90vw;
  height: 45vw;
  max-width: 560px;
  max-height: 315px;
}

.content #video .video-iframe {
  padding: 30px 0px;
}

.content .advantages {
  padding-top: 90px;
  padding-bottom: 85px;
}

.content .advantages p {
  max-width: 850px;
  font-size: 18px;
}

.content .advantages .advantages-items {
  text-align: left;
  flex-direction: column;
  width: 100%;
  margin-top: 50px;
}

.content .advantages .advantages-item {
  margin: 15px;
  flex-direction: row;
  align-items: flex-start;
}

.content .advantages .advantages-item-icon {
  color: #006600;
  font-size: 30px;
  margin-top: 0px;
  margin-right: 10px;
  display: none;
}

.content .advantages .advantages-items p {
  font-size: 16px;
  margin-top: 20px;
  line-height: 1.7;
  color: #666;
}

.content .advantages .advantages-items h3 {
  color: #006600;
  margin-top: 5px;
}

.content #subscribe {
  background-color: #98B589;
  padding: 80px 0px;
  background: url('../assets/images/painting@10p.jpg') no-repeat;
  background-size: cover;
  background-position: center;
}

.content #subscribe .subscribe-main {
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content #subscribe .subscribe-main h1 {
  color: #fff;
  font-size: 38px;
  letter-spacing: -0.8px;
}

.content #subscribe .subscribe-main p {
  color: #fff;
  font-weight: 400;
  font-size: 18px;
  max-width: 400px;
  letter-spacing: 0px;
  margin: 15px 0;
  line-height: 1.5;
}

.content #subscribe .subscribe-main img {
  padding-top: 30px;
}

@media (min-width: 1012px) {
  .content .highlights {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }

  .content .highlights .highlights-item {
    max-width: 320px;
  }

  .content .advantages .advantages-item-icon {
    display: block;
  }

  .content .advantages .advantages-items {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 30px;
  }

  .content .advantages .advantages-item {
    max-width: 450px;
    margin-top: 30px;
  }
}

@media (min-width: 1192px) {
  .content .highlights {
    background: url('../assets/images/arrows.png') no-repeat;
    background-position: center top;
  }

  .content .advantages .advantages-item {
    max-width: 550px;
  }
}
