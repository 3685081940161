body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  color: #2A2720;
  background-color: #fff;
}

p {
  line-height: 2;
}

h1, h2, h3, h4, h5, h6 {
  line-height: 1.2;
  margin: 0;
  padding: 0;
  letter-spacing: -0.8px;
}

h1 {
  font-size: 40px;
  font-weight: 500;
}

h2 {
  font-size: 36px;
  font-weight: 500;
}

h3 {
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0px;
}

.flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 16px;
}
